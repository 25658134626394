$primary: #242943;
$blue: #27476E;
$shadow: #bebebe;
$yellow: #FFC000;
$transition: all ease 0.2s;

.parallax {
  position: fixed !important;
  height: 60vh;
  width: 100%;
  top: 0;
  z-index: -1;
  object-fit: cover;

  &.home {
    height: 100vh;
  }
}

.banner-cover {
  position: fixed;
  height: 60vh;
  width: 100%;
  top: 0;
  z-index: -1;
  background-color: rgba($color: $primary, $alpha: 0.8);

  &.home {
    height: 100vh;
  }
}

.banner {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content-wrap {
  height: calc(100vh - 76px);
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 75%;
    color: white;
    text-align: center;
    padding-bottom: 5rem;
  }
}

.logo-wrap {
  display: flex;
  align-items: center;

  .navbar-logo {
    height: 35px;
    &.sepp {
      height: 50px;
    }
  }
  
  .vertical-bar {
    width: 2px;
    height: 35px;
    background-color: white;
  }
}

.down-arrow {
  font-size: 30px; 
  color: rgba(255,255,255,0.75);
  transition: $transition;
  cursor: pointer;
  position: absolute;
  bottom: 5%;
  &:hover {
      bottom: 4%;
      opacity: 0.5
  }
}

.header {
  text-transform: uppercase;
}

.sepp-logo {
  width: 25%;
  min-width: 160px;

  &.color {
    width: 70%;
  }
}

.content-list {
  border-bottom: 1px solid $shadow;
  white-space: pre-wrap;
  padding: 40px 0 30px;
  &.last {
      border-bottom: none;
  }
}

.video-wrap {
  padding: 40px 0; 
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.bio-pic {
  width: 100%;
  border-radius: 20px;
}