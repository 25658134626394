$primary: #242943;
$blue: #27476E;
$shadow: #bebebe;
$yellow: #FFC000;
$transition: all ease 0.2s;

.navbar-toggler {
  border-color: rgba($color: white, $alpha: 0.5);
}

.nav-link {
  color: white;
  margin: 0 8px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;

  &.active {
    color: $yellow !important;
  }
}

.contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  padding: 7px 20px !important;
  transition: $transition;
  border-radius: 20px;
  border: none;
  &:hover {
      background-color: $yellow;
      color: white !important;
      text-decoration: none;
  }
}

.nav-item {
  cursor: pointer;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 12px;
  font-weight: 500;
  &.active {
    color: $yellow;
  }
  &:hover {
    color: $yellow;
  }
}
