$primary: #242943;
$transition: all ease 0.2s;
$yellow: #FFC000;
$blue: #27476E;

#footer {
  color: white;
  padding: 20px 10px;
  background-color: $primary;
  a {
      color: white;
      text-decoration: none;
      &:hover {
          color: $yellow;
          transition: $transition;
      }
  }
  .copyright {
      font-size: 12px;
      opacity: 0.4;
  }
  .social-icon {
      background-color: $blue; 
      border-radius: 50%; 
      width: 50px; 
      height: 50px;
      &:hover {
          background-color: $yellow;
          transition: $transition;
      }
  }
}

.company-link {
    cursor: pointer;
    transition: $transition;

    &:hover {
        color: $yellow;
    }
}