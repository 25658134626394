$primary: #242943;
$shadow: #bebebe;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');


body {
  font-family: "Montserrat";
  background-color: white;
}

p {
  line-height: 2rem;
  font-size: calc(14px + 0.2vi);
}

h6 {
  color: $shadow;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 5%;
}







